import React, { useEffect, useState } from "react"
import moment from "moment"

import icon from "./assets/icon.png"
import titleSvg from "./assets/appIcon.svg"
import qrcodeIcon from "./assets/qrcode.png"
import arrowIcon from "./assets/arrow.png"
import { formatMeetingId } from "../../../utils/utils"
import { useIntl } from "gatsby-plugin-intl"
import styles from "./index.module.scss"
import packageInfo from "../../../../static/packageInfo.json"

interface Props {
  showInfo: Object
  meetingId: string
  meetingTitle: string
  meetingBeginTime: string
  isWindows: boolean
  isMac: boolean
  channel: string
}

function Teampro(props: Props) {
  const {
    showInfo,
    meetingId,
    meetingTitle,
    meetingBeginTime,
    isWindows,
    isMac,
    channel,
  } = props

  const intl = useIntl()

  const [namespace, setNamespace] = useState("teampro")

  useEffect(() => {
    const host = window.location.host
    if (host == "joint-plus.net") {
      setNamespace("jointplus")
    }
  }, [])

  function handleClick() {
    const href = document.location.href
    const regx = /https?/
    let url = ""

    if (isWindows || isMac) {
      url = href.replace(regx, channel)
    }

    // 当 url 为犀牛的域名的时候，应唤起的是犀牛的应用
    if (href.includes("rhinostar")) {
      url = href.replace(regx, "rhinomeet")
    }

    window.location.href = url
  }

  useEffect(() => {
    handleClick()
  }, [])

  const { mac, windows, android, ios } = packageInfo[namespace]

  return (
    <div className={styles.app}>
      <header>
        <div className={styles.logo}>
          <img src={icon} alt="app icon" className={styles.icon} />
          <img src={titleSvg} alt="title icon" className={styles.title} />
        </div>
      </header>

      <main>
        <div className={styles.container}>
          <img src={arrowIcon} alt="up arrow" />
          <p className={styles.tip}>
            {intl.formatMessage({ id: "点击“打开Teampro”即可入会！" })}
          </p>
          <p className={styles.info}>
            {intl.formatMessage({ id: "若当前页面没有反应，请点击”加入会议”" })}
          </p>

          <div className={styles.btn} onClick={handleClick}>
            {intl.formatMessage({ id: "加入会议" })}
          </div>
          <p className={styles.download}>
            {intl.formatMessage({ id: "若未安装 Teampro，" })}
            <a
              href={
                isWindows
                  ? windows.downloadLink
                  : isMac
                  ? mac.downloadLink
                  : "#"
              }
            >
              {intl.formatMessage({ id: "点击下载" })}
            </a>
          </p>

          {showInfo && (
            <div className={styles.meeting}>
              <div className={styles.label}>
                <span>{intl.formatMessage({ id: "会议主题：" })}</span>
                <span>{intl.formatMessage({ id: "会议号：" })}</span>
                <span>{intl.formatMessage({ id: "开始时间：" })}</span>
              </div>

              <div className={styles.value}>
                <span>{meetingTitle}</span>
                <span>{formatMeetingId(meetingId)}</span>
                <span>
                  {moment
                    .unix(parseInt(meetingBeginTime))
                    .format("YYYY-MM-DD HH:mm") +
                    intl.formatMessage({ id: "（北京时间）" })}
                </span>
              </div>
            </div>
          )}
        </div>
      </main>

      <footer>
        <div className={styles.content}>
          <div className={styles.left}>
            <div>
              <span>{intl.formatMessage({ id: "联系方式" })}</span>
            </div>
            <div>
              <span>{intl.formatMessage({ id: "客服电话：" })}</span>
              {"0755-3692-6418"}
            </div>
            <div>
              <span>{intl.formatMessage({ id: "联系邮箱：" })}</span>
              {"bd_teampro@youme.im"}
            </div>
          </div>

          <div className={styles.right}>
            <img src={qrcodeIcon} alt="QR Code" />
            <div className={styles.items}>
              <div>{intl.formatMessage({ id: "游密通讯云公众号" })}</div>
              <div>{intl.formatMessage({ id: "扫码关注" })}</div>
              <div>{intl.formatMessage({ id: "产品更新随时知" })}</div>
            </div>
          </div>
        </div>
      </footer>
    </div>
  )
}

export default React.memo(Teampro)
