import React, { useState, useEffect } from "react"
import { PageProps } from "gatsby"

import PC from "../components/PC/index"
import Mobile from "../components/Mobile/index"
import { useGetInvitationDownloadLink } from "../hooks/index"

interface Props extends PageProps {
  channel: string
  invitationCode: string
}

function Invitation(props: Props) {
  const [isInsideWeixin, setIsInsideWeixin] = useState(false)
  const [isInsideQQ, setIsInsideQQ] = useState(false)
  const [isWindows, setIsWindows] = useState(false)
  const [isMac, setIsMac] = useState(false)
  const [isIOS, setIsIOS] = useState(false)
  const [isAndroid, setIsAndroid] = useState(false)
  const [channel, setChannel] = useState("teampro")
  const [invitationCode, setInvitationCode] = useState("")

  useEffect(() => {
    setIsInsideQQ(checkPlatform("QQ"))
    setIsInsideWeixin(checkPlatform("MicroMessenger"))
    setIsAndroid(checkPlatform("Android"))
    setIsIOS(checkIsIOS())
    setIsWindows(checkPlatform("Windows"))
    setIsMac(checkPlatform("Macintosh"))
    setChannel(props?.channel)
    setInvitationCode(props?.invitationCode)
  }, [])

  const checkPlatform = (platform: string) => {
    return navigator.userAgent.includes(platform)
  }

  const checkIsIOS = () => {
    //查看是否是IOS手机
    var ua = navigator.userAgent.toLowerCase()
    var isIOS = false

    if (/iphone|ipad|ipod/.test(ua)) {
      isIOS = true
    } else if (/android/.test(ua)) {
      isIOS = false
    }
    return isIOS
  }

  /** 判断一下当前是否为 PC 浏览器环境 */
  const isPC = () => {
    return !isInsideWeixin && (isWindows || isMac)
  }

  const downloadLink = useGetInvitationDownloadLink({
    isWin: isWindows,
    isMac,
    isAndroid,
    isIOS,
  })

  console.log(`src/pages/invitation.tsx${downloadLink}`)

  return !isPC() ? (
    <Mobile
      isInsideWeixin={isInsideWeixin}
      isAndroid={isAndroid}
      isIOS={isIOS}
      channel={channel}
      downloadLink={downloadLink}
    />
  ) : (
    <PC
      isMac={isMac}
      isWindows={isWindows}
      channel={channel}
      invitationCode={invitationCode}
      downloadLink={downloadLink}
    />
  )
}

export default Invitation
