import React from "react"

import Teampro from "./Teampro"
import Tongli from "./Tongli"
import Jointplus from "./Jointplus"
import Yunlu from "./Yunlu"
import Yunshang from "./Yunshang"
import Taishanghui from "./Taishanghui"

interface Props {
  isIOS: boolean
  isAndroid: boolean
  isInsideWeixin: boolean
  channel: string
  downloadLink?: string
}

function Mobile(props: Props) {
  const { isIOS, isAndroid, isInsideWeixin, channel, downloadLink } = props

  const pageProps = {
    isIOS,
    isAndroid,
    isInsideWeixin,
  }

  if (typeof window !== "undefined") {
    const host = window.location.host
    if (host.includes("joint-plus.net")) {
      return <Jointplus {...pageProps} />
    }
    if (host.includes("meeting.yunlucloud.com")) {
      return <Yunlu {...pageProps} />
    }
    if (host.includes("meeting.ts960.com")) {
      return <Taishanghui {...pageProps} downloadLink={downloadLink} />
    }
  }

  switch (channel) {
    case "teampro":
      return <Teampro {...pageProps} />
    case "tongli":
      return <Tongli {...pageProps} />
    case "jointplus":
      return <Jointplus {...pageProps} />
    case "yunlu":
      return <Yunlu {...pageProps} />
    case "yunshang":
      return <Yunshang {...pageProps} />
    case "taishanghui":
      return <Taishanghui {...pageProps} downloadLink={downloadLink} />
    default:
      return <Teampro {...pageProps} />
  }
}

export default React.memo(Mobile)
