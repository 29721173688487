import React, { useState, useEffect } from "react"
import axios from "axios"

import Teampro from "./Teampro"
import Tongli from "./Tongli"
import Jointplus from "./Jointplus"
import Yunlu from "./Yunlu"
import Yunshang from "./Yunshang"
import Taishanghui from "./Taishanghui"

interface Props {
  isMac: boolean
  isWindows: boolean
  channel: string
  invitationCode: string
  downloadLink?: string | undefined
}

export default function PC(props: Props) {
  const [showInfo, setShowInfo] = useState(false)
  const [meetingId, setMeetingId] = useState("")
  const [meetingTitle, setMeetingTitle] = useState("")
  const [meetingBeginTime, setMeetingBeginTime] = useState("")

  useEffect(() => {
    const inviteCode = props.invitationCode
    if (inviteCode) {
      axios
        .post("/api2/GetMeetingFromInviteCode", { invite_code: inviteCode })
        .then(result => {
          const { data } = result
          console.log(data)
          if (data && !data.code) {
            const { meeting_info } = data
            const {
              meeting_room_id,
              meeting_room_begin_time,
              meeting_room_title,
            } = meeting_info

            setShowInfo(true)
            setMeetingId(meeting_room_id.toString())
            setMeetingBeginTime(meeting_room_begin_time)
            setMeetingTitle(meeting_room_title)
          } else {
            console.error(data.errmsg)
          }
        })
        .catch(e => {
          console.error(e.message)
        })
    }

    document.title = "邀请页面"
  }, [])

  const { isWindows, isMac, channel } = props
  const pageProps = {
    showInfo,
    meetingId,
    meetingTitle,
    meetingBeginTime,
    isWindows,
    isMac,
    channel,
  }

  if (typeof window !== "undefined") {
    const host = window.location.host
    if (host.includes("joint-plus.net")) {
      return <Jointplus {...pageProps} />
    }
    if (host.includes("meeting.yunlucloud.com")) {
      return <Yunlu {...pageProps} />
    }
    if (host.includes("meeting.ts960.com")) {
      console.log(`src/components/PC/index.tsx${props.downloadLink}`)
      return <Taishanghui {...pageProps} downloadLink={props.downloadLink} />
    }
  }

  switch (channel) {
    case "teampro":
      return <Teampro {...pageProps} />
    case "tongli":
      return <Tongli {...pageProps} />
    case "jointplus":
      return <Jointplus {...pageProps} />
    case "yunlu":
      return <Yunlu {...pageProps} />
    case "yunshang":
      return <Yunshang {...pageProps} />
    case "taishanghui":
      return <Taishanghui {...pageProps} downloadLink={props.downloadLink} />
    default:
      return <Teampro {...pageProps} />
  }
}
