import React from "react"
import { injectIntl } from "gatsby-plugin-intl"
import classnames from "classnames"

import styles from "./index.module.css"
import icon from "./assets/icon.png"
import titleSvg from "./assets/appIcon.svg"
import bg from "./assets/bg.jpg"
import tipPic from "./assets/tip.png"
import packageInfo from "../../../../static/packageInfo.json"

interface Props {
  isIOS: boolean
  isAndroid: boolean
  isInsideWeixin: boolean
  intl: {
    formatMessage: (val: object) => string
  }
}

interface State {
  namespace: string
}

class Mobile extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props)
    this.state = {
      namespace: "teampro",
    }
  }

  componentDidMount() {
    const host = window.location.host
    if (host === "joint-plus.net") {
      this.setState({
        namespace: "jointplus",
      })
    }
  }

  handleClick = () => {
    const href = document.location.href
    const regx = /https?/
    let url = ""

    const { isIOS, isAndroid } = this.props

    if (isIOS) {
      url = href.replace(regx, "yam")
    }

    if (isAndroid) {
      url = href.replace(regx, "yamapp")
    }

    // 当 url 为犀牛的域名的时候，应唤起的是犀牛的应用
    if (href.includes("rhinostar")) {
      url = href.replace(regx, "rhinomeet")
    }

    window.location.href = url
  }

  render() {
    const { isInsideWeixin, isAndroid, isIOS, intl } = this.props
    const { namespace } = this.state

    const { ios, android } = packageInfo[namespace]

    const locale = intl.locale.replace('-', '_')

    return (
      <div
        className={styles.mobile}
        style={
          !isInsideWeixin
            ? { background: `url(${bg})`, backgroundSize: "cover" }
            : {}
        }
      >
        <div className={styles.logo}>
          <img src={icon} alt="app icon" className={styles.icon} />
          <img src={titleSvg} alt="title icon" className={styles.title} />
        </div>

        <div className={styles.tip}>
          {intl.formatMessage({ id: "若已安装 Teampro，请单击“加入会议”" })}
        </div>
        <div className={styles.btn} onClick={this.handleClick}>
          {intl.formatMessage({ id: "加入会议" })}
        </div>
        <div className={styles.download}>
          {intl.formatMessage({ id: "若未安装 Teampro，" })}
          <a
            href={
              isAndroid ? android.downloadLink : isIOS ? ios.downloadLink : "#"
            }
          >
            {intl.formatMessage({ id: "立即下载" })}
          </a>
        </div>

        {isInsideWeixin && (
          <div className={classnames(styles.mask, isIOS ? styles[`ios_${locale}`] : styles[`android_${locale}`])}>
          </div>
        )}
      </div>
    )
  }
}

export default injectIntl(Mobile)
