/**
 * Separate meeting id to 'xxx-xxx-xxx'
 */
export const formatMeetingId = (id: string) => {
  const ids = id.split('')
  const len = ids.length
  let results: string[] = []

  for(let i = 0; i < len; i += 3) {
    results = results.concat(ids.slice(i, i + 3))
    if (i + 3 < len) {
      results.push('-')
    }
  }

  return results.join('')
}
