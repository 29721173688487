import React, { useState, useEffect } from "react"
import { injectIntl } from "gatsby-plugin-intl"
import classnames from "classnames"

import styles from "./index.module.css"
import icon from "./assets/icon.png"
import bg from "./assets/bg.jpg"
import packageInfo from "../../../../static/packageInfo.json"
import { requestDownloadURL } from "../../../api/requestDownloadURL"

interface Props {
  isIOS: boolean
  isAndroid: boolean
  isInsideWeixin: boolean
  intl: {
    formatMessage: (val: object) => string
  }
}

function Mobile(props: Props) {
  const namespace = "yunshang"

  function handleClick() {
    const href = document.location.href
    const regx = /https?/
    let url = ""

    const { isIOS, isAndroid } = props

    if (isIOS) {
      url = href.replace(regx, "yunshang")
    }

    if (isAndroid) {
      url = href.replace(regx, "yamapp")
    }

    window.location.href = url
  }

  const { isInsideWeixin, isAndroid, isIOS, intl } = props

  const { ios, android } = packageInfo[namespace]

  const locale = intl.locale.replace("-", "_")

  const [downloadLink, setDownloadLink] = useState("#")
  useEffect(() => {
    requestDownloadURL("yunshang").then(result => {
      const packageInfo = result.data

      const downloadLink = packageInfo.find(
        element => element.platform === (isAndroid ? "Android" : "iOS")
      )?.downloadLink

      setDownloadLink(downloadLink || "#")
    })
  }, [])

  return (
    <div
      className={styles.mobile}
      style={
        !isInsideWeixin
          ? { background: `url(${bg})`, backgroundSize: "cover" }
          : {}
      }
    >
      <div className={styles.logo}>
        <img src={icon} alt="title icon" className={styles.title} />
      </div>

      <div className={styles.tip}>
        {intl.formatMessage({ id: "若已安装云尚会议，请单击“加入会议”" })}
      </div>
      <div className={styles.btn} onClick={handleClick}>
        {intl.formatMessage({ id: "加入会议" })}
      </div>
      <div className={styles.download}>
        {intl.formatMessage({ id: "若未安装云尚会议，" })}
        <a href={downloadLink}>{intl.formatMessage({ id: "立即下载" })}</a>
      </div>

      {isInsideWeixin && (
        <div
          className={classnames(
            styles.mask,
            isIOS ? styles[`ios_${locale}`] : styles[`android_${locale}`]
          )}
        ></div>
      )}
    </div>
  )
}

export default injectIntl(Mobile)
