import React, { useEffect } from "react"
import moment from "moment"

import icon from "./assets/icon.png"
import arrowIcon from "./assets/arrow.png"
import { formatMeetingId } from "../../../utils/utils"

import styles from "./index.module.scss"
import { useIntl } from "gatsby-plugin-intl"

interface Props {
  showInfo: Object
  meetingId: string
  meetingTitle: string
  meetingBeginTime: string
  isWindows: boolean
  isMac: boolean
  channel: string
}

const appName = "joint+"

function Teampro(props: Props) {
  const {
    showInfo,
    meetingId,
    meetingTitle,
    meetingBeginTime,
    isWindows,
    isMac,
    channel,
  } = props

  const intl = useIntl()

  function handleClick() {
    const href = document.location.href
    const regx = /https?/
    let url = ""

    if (isWindows || isMac) {
      url = href.replace(regx, channel)
    }

    window.location.href = url
  }

  useEffect(() => {
    handleClick()
  }, [])

  return (
    <div className={styles.app}>
      <header>
        <div className={styles.logo}>
          <img src={icon} alt="app icon" className={styles.icon} />
        </div>
      </header>

      <main>
        <div className={styles.container}>
          <img src={arrowIcon} alt="up arrow" />
          <p className={styles.tip}>
            {intl.formatMessage({ id: `點擊“打開${appName}”即可入會！` })}
          </p>
          <p className={styles.info}>
            {intl.formatMessage({ id: "若當前頁面沒有反應，請點擊“加入會議”" })}
          </p>

          <div className={styles.btn} onClick={handleClick}>
            {intl.formatMessage({ id: "加入會議" })}
          </div>

          {showInfo && (
            <div className={styles.meeting}>
              <div className={styles.label}>
                <span>{intl.formatMessage({ id: "會議主題：" })}</span>
                <span>{intl.formatMessage({ id: "會議號：" })}</span>
                <span>{intl.formatMessage({ id: "開始時間：" })}</span>
              </div>

              <div className={styles.value}>
                <span>{meetingTitle}</span>
                <span>{formatMeetingId(meetingId)}</span>
                <span>
                  {moment
                    .unix(parseInt(meetingBeginTime))
                    .format("YYYY-MM-DD HH:mm") +
                    intl.formatMessage({ id: "（北京時間）" })}
                </span>
              </div>
            </div>
          )}
        </div>
      </main>

      <footer>
        <div className={styles.content}></div>
      </footer>
    </div>
  )
}

export default React.memo(Teampro)
