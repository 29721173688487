import React from "react"
import { injectIntl } from "gatsby-plugin-intl"
import styles from "./index.module.css"
import icon from "./assets/icon.png"
import bg from "./assets/bg.jpg"
import tipPic from "./assets/tip.png"
import classnames from "classnames"

interface Props {
  isIOS: boolean
  isAndroid: boolean
  isInsideWeixin: boolean
  intl: {
    formatMessage: (val: object) => string
  }
}

class Mobile extends React.Component<Props> {
  handleClick = () => {
    const href = document.location.href
    const regx = /https?/
    let url = ""

    const { isIOS, isAndroid } = this.props

    if (isIOS) {
      url = href.replace(regx, "yunlu")
    }

    if (isAndroid) {
      url = href.replace(regx, "yamapp")
    }

    window.location.href = url
  }

  render() {
    const { isInsideWeixin, intl, isIOS } = this.props

    const locale = intl.locale.replace("-", "_")

    return (
      <div
        className={styles.mobile}
        style={
          !isInsideWeixin
            ? { background: `url(${bg})`, backgroundSize: "cover" }
            : {}
        }
      >
        <div className={styles.logo}>
          <img src={icon} alt="app icon" className={styles.icon} />
        </div>

        <div className={styles.tip}>
          {intl.formatMessage({
            id: "若已安装云鹭会议，请单击“加入会议”",
          })}
        </div>
        <div className={styles.btn} onClick={this.handleClick}>
          {intl.formatMessage({ id: "加入会议" })}
        </div>

        {isInsideWeixin && (
          <div
            className={classnames(
              styles.mask,
              isIOS ? styles[`ios_${locale}`] : styles[`android_${locale}`]
            )}
          ></div>
        )}
      </div>
    )
  }
}

export default injectIntl(Mobile)
